import React, { useEffect, useState } from "react";

const PublitioAPI = require('publitio_js_sdk').default
const publitio = new PublitioAPI('onQz4inwRt7Hrsl267Vb', 'MqMgniRWkee8hytGf6asLzVxDmu65s4f')

export default function Publit(props) {
  const [playerUrl, setPlayerUrl] = useState(0)

  useEffect(() => {
    publitio.call('/files/player/'+props.publitFileId, 'GET', {
      player: props.publitPlayer
    })
      .then(resultData => {
        setPlayerUrl(resultData.iframe_html)
      })
  }, [])

  return (
    <div className="video" dangerouslySetInnerHTML={{__html: playerUrl}}></div>
  );
}



