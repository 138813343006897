export default {
    colors: {
      primary: '#B026FF',
      background: '#FFFFFF',
      shape: `#F2F2FA`,
      title: `#3D3D4D`,
      text: `#6C6C80`,
      components: {
        blockquote: {
          background: `#F2F2FA`,
          text: `#2d3748`,
        },
      },
    },
    fonts: {
      body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
    },
  };