// gatsby-browser.js
import * as React from 'react'
import { AuthConfig } from "react-use-auth";
import { Auth0 } from "react-use-auth/auth0";
import { navigate } from "gatsby";
import Prism from 'prism-react-renderer/prism'

(typeof global !== "undefined" ? global : window).Prism = Prism

export const wrapPageElement = ({ element }) => (
    <>
        <AuthConfig
            authProvider={Auth0}
            navigate={navigate}
            params={{
                domain: "login.hackerops.dev",
                clientID: "RCyXuTZ6rlwlRp83VKZVas9AACaMP4H1",
                audience: "https://dev-y8o8tdap.us.auth0.com/api/v2/"
            }}
        />
        {element}
    </>
);

require("prismjs/components/prism-hcl")
require("prismjs/components/prism-csharp")
require("prismjs/components/prism-powershell")
require("prismjs/components/prism-docker")